<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <div class="cons">
      <div class="xqmain">
        <div class="xqmain-maintitle">
          <div>典型文献</div>
          <div>
            <el-button type="primary" @click="comeback">返回上级</el-button>
          </div>
        </div>
        <div class="xqmain-title">{{ wzxqobj.arttitle }}</div>

        <div class="dxwx-con">
          <div
            class="dxwx-list"
            v-if="wzxqobj.zhaiyao && wzxqobj.zhaiyao != ''"
          >
            <div class="dxwx-list-title">文献摘要：</div>
            <div class="dxwx-list-con">{{ wzxqobj.zhaiyao }}</div>
          </div>
          <div
            class="dxwx-list"
            v-if="wzxqobj.keyword && wzxqobj.keyword != ''"
          >
            <div class="dxwx-list-title">文献关键词：</div>
            <div class="dxwx-list-con">{{ wzxqobj.keyword }}</div>
          </div>
          <div class="dxwx-list" v-if="ztflhlist.length > 0">
            <div class="dxwx-list-title">中图分类号：</div>
            <div class="dxwx-list-con">
              <div
                class="dxwx-list-cons"
                v-for="(item, key) in ztflhlist"
                :key="key"
              >
                <span class="dxwx-list-cons-title">{{ [key + 1] }}</span
                ><span
                  class="dxwx-list-cons-list"
                  v-for="(ele, index) in item"
                  :key="index"
                  @click="clickztflh(ele.Id)"
                >
                  <span v-if="index != 0">/</span>
                  <span class="dxwx-list-cons-listwz"> {{ ele.zname }}（{{ ele.zcode }}） </span></span
                >
              </div>
            </div>
          </div>
          <div class="dxwx-list" v-if="wzxqobj.writerlist.length>0">
            <div class="dxwx-list-title">作者姓名：</div>
            <div class="dxwx-list-con">
              <span class="dxwx-list-con-span" v-for="(item,key) in wzxqobj.writerlist" :key="key" @click="clickzuozhe(item)">{{item}}</span>
            </div>
          </div>
          <div class="dxwx-list" v-if="wzxqobj.orglist.length>0">
            <div class="dxwx-list-title">作者机构：</div>
            <div class="dxwx-list-con">{{ wzxqobj.org }}</div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">文献出处：</div>
            <div class="dxwx-list-con">
              <a href="javascript:;" @click="clickqk(wzxqobj.qkid)">{{
                wzxqobj.qkname
              }}</a>
            </div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">引用格式：</div>
            <div class="dxwx-list-con">
              [1]{{ wzxqobj.writerwenxian }}.{{ wzxqobj.arttitle }}[J].{{
                wzxqobj.qkname
              }},{{ wzxqobj.year }}({{ wzxqobj.qs }}):{{ wzxqobj.wzpagefw }}
            </div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">A类：</div>
            <div class="dxwx-list-con">{{ wzxqobj.aastr }}</div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">B类：</div>
            <div class="dxwx-list-con">{{ wzxqobj.bbstr }}</div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">AB值：</div>
            <div class="dxwx-list-con">{{ wzxqobj.abvalue }}</div>
          </div>
        </div>
        <div class="dxwx-con xswx">
          <div class="xswx-title">相似文献</div>
          <div class="xswx-ul">
            <div v-for="(item, key) in xswzlistnew" :key="key">
              <router-link
                target="_blank"
                :to="{ name: '/xkzs/delwz', query: { id: item.Id } }"
              >
                <div class="xswx-li">
                  <div>{{ item.arttitle }}</div>
                  <div class="xswx-li-zz">{{ item.writer }}-{{ item.org }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">
        机标中图分类号，由域田数据科技根据网络公开资料自动分析生成，仅供学习研究参考。
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { get } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();

let wzxqobj = reactive({});
let xswzlist = reactive([]);
let xswzlistnew = reactive([]);
// 中图分类号合集
let ztflhlist = reactive([]);

const getdetial = () => {
  get("/Journal/GetQkarticleDet", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res);
    ztflhlist.push(...JSON.parse(res.data.newflharr));
    // console.log(ztflhlist)
    Object.assign(wzxqobj, res.data);
    wzxqobj.writerwenxian = "";
    // 处理作者，3个以上不用全部显示，加等字
    // console.log(wzxqobj.writer.split(';'))
    wzxqobj.writerlist=[]
    wzxqobj.writer.split(";").forEach((e, i) => {
      // 拆分后的作者list
      wzxqobj.writerlist.push(e)
      if (wzxqobj.writer.split(";").length > 3) {
        if (i < 2) {
          wzxqobj.writerwenxian += e + ",";
        }
        if (i == 2) {
          wzxqobj.writerwenxian += e + "等";
        }
      } else {
        if (i < wzxqobj.writer.split(";").length - 1) {
          wzxqobj.writerwenxian += e + ",";
        } else {
          wzxqobj.writerwenxian += e;
        }
      }
    });

    // 处理机构
    wzxqobj.orglist=[]
    wzxqobj.org.split(";").forEach((e, i) => {
      // 拆分后的作者list
      wzxqobj.orglist.push(e)
    });

    document.title = wzxqobj.arttitle;
    // 页面标题描述摘要
    let titles = "";
    if (wzxqobj.org && wzxqobj.org != "" && wzxqobj.org.split(" ")[1]) {
      titles =
        wzxqobj.org.split(" ")[1] +
        "|" +
        wzxqobj.writer +
        "|" +
        res.data.arttitle;
    } else {
      titles = wzxqobj.writer + "|" + res.data.arttitle;
    }
    document.title = titles;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", res.data.keyword);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", res.data.zhaiyao);
  });
};
getdetial();
// 获取相似文章
const getxswz = () => {
  get("/Journal/GetXswzList", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res);
    if (res.data) {
      xswzlist.push(...res.data);
    }
    xswzlist.forEach((e) => {
      e.writerwenxian = "";
      e.writer.split(";").forEach((ele, key) => {
        if (key < 2) {
          e.writerwenxian += ele + ",";
        }
        if (key == 2) {
          e.writerwenxian += ele + "等";
        }
      });
    });
    // 去重
    xswzlistnew.push(...unique(xswzlist));
    xswzlist.forEach((e) => {
      if (xswzlistnew.length < 10) {
        if (
          xswzlistnew.filter((ele) => {
            return ele.Id == e.Id;
          }).length == 0
        ) {
          xswzlistnew.push(e);
        }
      }
    });
  });
};

getxswz();

// 点击中图分类号
const clickztflh = (id) => {
  window.open('//ztflh.yuanpingjia.com.cn/Home/Flh/'+id)
  // const url = router.resolve({
  //   path: "/flh/list",
  //   query: {
  //     id: id,
  //   },
  // });
  // window.open(url.href);
};

// 点击作者
const clickzuozhe=zuozhe=>{
  const url = router.resolve({
    path: "/zuozhe",
    query: {
      data: encodeURIComponent(zuozhe),
    },
  });
  window.open(url.href);
}

// 点击相似文章
const clickxswz = (id) => {
  const url = router.resolve({
    path: "/xkzs/delwz",
    query: {
      id: id,
    },
  });
  window.open(url.href);
};
// 去重
const unique = (arr1) => {
  const res = new Map();
  return arr1.filter((item) => !res.has(item.qkid) && res.set(item.qkid, 1));
};
// 跳转期刊
const clickqk = (id) => {
  const url = router.resolve({
    path: "/xkzs/del",
    query: {
      id: id,
    },
  });
  window.open(url.href);
};
// 返回
const comeback = () => {
  window.history.go(-1);
};
</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0 50px 0;
}
.xqmain-maintitle {
  border-bottom: 3px double #333;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xqmain-title {
  font-size: 24px;
}
.xqmain-subtitle {
  padding: 20px 0;
}
.xqmain-subtitle-zuozhe {
  color: #2466b1;
}
.xqmain-subtitle-org {
  color: #999;
  padding: 0 20px;
}
.xqmain-subtitle-qs {
  color: #999;
}
.xqmain-zhaiyao {
  padding: 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  line-height: 32px;
}
.xqmain-zhaiyao > span {
  font-weight: bold;
}
.tips {
  color: #999;
  font-size: 12px;
  padding-top: 20px;
}
.dxwx-con {
  margin-top: 15px;
  font-size: 14px;
  padding: 10px 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
}
.dxwx-list {
  margin: 10px 0;
}
.dxwx-list:after {
  content: "";
  display: table;
  clear: both;
}
.dxwx-list-title {
  width: 90px;
  float: left;
  line-height: 20px;
  font-weight: bold;
}
.dxwx-list-con {
  width: calc(100% - 90px);
  float: left;
  line-height: 20px;
}
.dxwx-list-cons {
  width: 100%;
  padding: 0 0 5px 0;
}
.dxwx-list-cons-title {
  padding-right: 5px;
}
.dxwx-list-cons-listwz{
  margin: 0 5px;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}
.dxwx-list-con-span{
  margin: 0 5px;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}
.xswx {
  padding: 20px;
  margin-top: 10px;
}
.xswx-title {
  font-weight: bold;
  padding-bottom: 10px;
}
.xswx-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  line-height: 20px;
  padding: 5px 0;
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
}
.xswx-li:hover {
  background: #f2f2f2;
}
.xswx-li-zz {
  max-width: 400px;
  height: 20px;
  line-height: 20px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xswx-ul a:link,
.xswx-ul a:visited {
  color: #333;
  text-decoration: none;
}
</style>
